import React from "react";
import {
  FaUsers,
  FaFlag,
  FaGlobeEurope,
  FaLocationArrow,
  FaClock,
  FaArrowCircleLeft,
} from "react-icons/fa";
import { BsPeople, BsClock } from "react-icons/bs";
import format from "date-fns/format";
import { useQuery } from "react-query";
import Layout from "../../components/layout";
import isDate from "lodash/isDate";
import { Link } from "gatsby";
import { ErrorBoundary } from "react-error-boundary";
export default function Event({ params }) {
  const query = useQuery(["event", params.id], async () => {
    return fetch(`/api/event?event_id=${params.id}`).then((res) => res.json());
  });

  const event = query?.data;
  return (
    <div
      className="bg-center bg-no-repeat bg-cover"
      style={{
        backgroundAttachment: "fixed",
        backgroundImage: `url(${event?.cover?.source})`,
      }}
    >
      <div className="bg-white/80 dark:bg-dark/80">
        <Layout transparent>
          {query?.data && (
            <div>
              <div className="container py-10 lg:py-20">
                <div className="grid max-w-3xl grid-cols-1 mx-auto">
                  <Link
                    to="/"
                    className="inline-flex items-center mb-8 space-x-2"
                  >
                    <FaArrowCircleLeft />
                    <span>Zurück </span>
                  </Link>
                  <div className=" bg-white/90 dark:bg-black/90 ring-8 ring-black/20 dark:ring-white/20">
                    <div
                      className="h-56"
                      style={{
                        background: `url(${event?.cover?.source}) no-repeat`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <div className="p-4 prose dark:prose-dark max-w-none">
                      <h1>{event.name}</h1>
                      <h3>Details:</h3>
                      <Details Icon={FaUsers}>
                        <span>
                          Attending:{" "}
                          <strong>{event.attending_count ?? 0}</strong>
                        </span>{" "}
                        <span>/</span>
                        <span>
                          Maybe: <strong> {event.maybe_count}</strong>
                        </span>{" "}
                        <span>/</span>
                        <span>
                          Declined: <strong> {event.declined_count}</strong>
                        </span>{" "}
                        <span>/</span>
                        <span>
                          No replay: <strong>{event.noreply_count}</strong>
                        </span>
                      </Details>
                      <Details Icon={FaFlag}>
                        Event by <strong>{event?.owner?.name}</strong>
                      </Details>
                      <Details Icon={FaLocationArrow}>
                        <strong>{event?.place?.name}</strong>
                      </Details>
                      <Details Icon={FaClock}>
                        <span>
                          {event?.start_time &&
                            format(
                              new Date(event?.start_time),
                              "EEEE, LLLL dd, yyyy"
                            )}
                          {event?.start_time && " at "}
                          {event?.start_time &&
                            format(new Date(event?.start_time), "p O")}{" "}
                          {event?.end_time && "-"}{" "}
                          {event?.end_time &&
                            format(new Date(event?.end_time), "p O")}
                        </span>{" "}
                      </Details>
                      <Details Icon={FaGlobeEurope}>
                        <span className="capitalize">{event?.type}</span>
                      </Details>

                      <h3>About:</h3>
                      <p
                        className="whitespace-pre-line"
                        dangerouslySetInnerHTML={{
                          __html: registerLinks(event?.description) ?? "",
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Layout>
      </div>
    </div>
  );
}

function Boundry({ children }) {
  try {
    return children;
  } catch (error) {
    return null;
  }
}

function Details({ Icon, children }) {
  return (
    <p className="grid gap-4 lg:grid-cols-[auto,1fr]">
      <Icon className="text-2xl text-dark dark:text-white "> </Icon>
      <div className="space-x-2">{children}</div>
    </p>
  );
}

function registerLinks(text) {
  return text.replace(
    /(https?):\/\/(www\.)?[a-z0-9\.:].*?(?=\s)/g,
    `<a href="$&" target="_blank">$&</a>`
  );
}
